// styles
import './index.scss';


// components
import Testimonial from './components/testimonial/testimonial';
import Cookies from './components/cookies/cookies';

// init
let testimonial = document.querySelector('.s-testimonial-slider');
if (testimonial) {
  testimonial = new Testimonial();
  testimonial.init();
}

let cookies = document.querySelector('.cookie-banner');
if (cookies) {
  cookies = new Cookies();
  cookies.init();
}

// js
import FAQ from './components/faq/faq';
import Nav from './components/nav/nav';
import Scroll from './components/smooth-scroll/smooth-scroll';