import { gsap } from 'gsap';

export default class Testimonial {
  constructor() {
    this.dom = {
      el: document.querySelector('.s-testimonial-slider'),
      slide: document.querySelectorAll('.s-testimonial-slider--slide'),
      controls: document.querySelector('.s-testimonial-slider--controls'),
      prev: document.querySelector('.s-testimonial-slider--controls__prev'),
      next: document.querySelector('.s-testimonial-slider--controls__next'),
    }

    this.options = {
      speed: 1,
      ease: 'cubic.inOut',
    }

    this.state = {
      current: 0,
    }
  }

  init() {
    this.addEvents();
    console.log(this.dom.slide[1]);
  }

  addEvents() {
    this.dom.prev.addEventListener('click', this.animatePrev);
    this.dom.next.addEventListener('click', this.animateNext);
  }

  removeEvents() {
    this.dom.prev.removeEventListener('click', this.animatePrev);
    this.dom.next.removeEventListener('click', this.animateNext);
  }

  animatePrev = () => {
    gsap.fromTo(this.dom.slide[this.state.current], {
      x: 0,
      autoAlpha: 1,
    }, {
      autoAlpha: 0,
      x: window.innerWidth,
      ease: this.options.ease,
      duration: this.options.speed,
    });

    if (this.state.current === 0) {
      this.state.current = this.dom.slide.length - 1;
    } else {
      this.state.current--;
    }

    gsap.fromTo(this.dom.slide[this.state.current], {
      autoAlpha: 0,
      x: -window.innerWidth,
    }, {
      x: 0,
      autoAlpha: 1,
      ease: this.options.ease,
      duration: this.options.speed,
    });
  }

  animateNext = () => {
    gsap.fromTo(this.dom.slide[this.state.current], {
      x: 0,
      autoAlpha: 1,
    }, {
      autoAlpha: 0,
      x: -window.innerWidth,
      ease: this.options.ease,
      duration: this.options.speed,
    });

    if (this.state.current === this.dom.slide.length - 1) {
      this.state.current = 0;
    } else {
      this.state.current++;
    }

    gsap.fromTo(this.dom.slide[this.state.current], {
      autoAlpha: 0,
      x: window.innerWidth,
    }, {
      autoAlpha: 1,
      x: 0,
      ease: this.options.ease,
      duration: this.options.speed,
    });
  }
}
