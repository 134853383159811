const body = document.body;
const burger = document.querySelector('.c-burger');

//changing colors navigation
if (body.classList.contains('page-lookbook') || body.classList.contains('page-contact')) {
  burger.classList.add('is-white');

  document.addEventListener('scroll', () => {
    const scroll = document.body.getBoundingClientRect().top;

    if (scroll < -window.innerHeight) {
      burger.classList.remove('is-white');
    } else {
      burger.classList.add('is-white');
    }
  });
}

// mobile menu slide open close
document.querySelector('.c-burger').addEventListener('click', function () {
  const mobileMenu = document.querySelector('.c-burger');
  mobileMenu.classList.toggle("mobile-menu-toggle");

  const mobileNav = document.querySelector('.m-nav');
  mobileNav.classList.toggle('m-nav-toggle');

  document.body.classList.toggle('is-hidden');
});
