import gsap from 'gsap';

export default class Cookies {
  constructor() {
    this.section = document.querySelector('.cookie-banner');

    this.close = this.section.querySelector('.cookie-close-button');
    this.accept = this.section.querySelector('.cookie-accept-button');
  }

  init() {
    this.setCookie = document.cookie.indexOf('set=');
    this.enabledCookie = document.cookie.indexOf('enabled=');

    if (this.setCookie == -1) {
      this.events();
      console.log('cookie is unset');
      gsap.to(this.section, {
        autoAlpha: 1,
      });
    }
    else {
      console.log('cookie is set');
      if (this.enabledCookie == -1) {
        console.log('disable analytics');
        // insert ga code here
        window['ga-disable-G-M87YZ9PMS7'] = true;
      } else {
        document.cookie = 'enabled=yes';
        console.log('enable analytics');
      }
    }
  }

  events() {
    this.accept.addEventListener('click', this.enabled);
    this.close.addEventListener('click', this.disabled);
  }

  disabled = () => {
    console.log('disable analytics');
    // insert ga code here
    window['ga-disable-G-M87YZ9PMS7'] = true;
    this.hide();
  }

  enabled = () => {
    document.cookie = 'enabled=yes';
    console.log('enable analytics');
    this.hide();
  }

  hide = () => {
    document.cookie = 'set=yes';
    gsap.to(this.section, {
      autoAlpha: 0,
      onComplete: () => {
        this.section.remove();
      }
    });
  }
}
